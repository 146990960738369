import React from "react";
import { graphql } from "gatsby";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import RightColours from "../utilities/RightColours";
import LeftColours from "../utilities/LeftColours";
import {
  tryEncord,
  tryEncordVisual,
  checkOutOnGithubVisual,
  tryEncordCTAAnnotateVisual,
  tryEncordTodayBar,
  tryOnGithubBar,
  encordVsCta,
  medicalCta,
  samCTA,
  debugModelsEACTA,
  trainingDataCTA,
  fixErrorsCTA,
  medicalCTALight,
  productSamCta,
  gptVisionCTA,
  apolloCTA,
  activeCTA,
  productActiveCTA,
  rlhfCTA,
  webinarVLMCTA,
} from "../utilities/EncordCTAs";
import { addAltToImgTags } from "../components/V3/helper";
import SEO from "../components/seo";
import SocialMediaHandles from "../partials/SocialMediaHandles";
import CtaSide from "../partials/CtaSide";
import "../styles/page/glossary_detail_page.scss";
import CustomLink from "../components/CustomLink";
import { Helmet } from "react-helmet";
import { pickSlice } from "../utilities/helpers";
import Layout from "../components/layout";

const stringManipulation = (html) => {
  let __html = html
    ?.replaceAll("{{try_encord}}", tryEncord())
    ?.replaceAll("{{try_encord_visual}}", tryEncordVisual())
    ?.replaceAll("{{check_out_on_github_visual}}", checkOutOnGithubVisual())
    ?.replaceAll("{{fix_errors_CTA}}", fixErrorsCTA())
    ?.replaceAll(
      "{{try_encord_CTA_annotate_visual}}",
      tryEncordCTAAnnotateVisual()
    )
    ?.replaceAll("{{try_encord_today_bar}}", tryEncordTodayBar())
    ?.replaceAll("{{SAM_CTA}}", samCTA())
    ?.replaceAll("{{debug_models_EA_CTA}}", debugModelsEACTA())
    ?.replaceAll("{{Training_data_CTA}}", trainingDataCTA())
    ?.replaceAll("{{try_on_github_bar}}", tryOnGithubBar())
    ?.replaceAll("{{Encord_vs_CTA}}", encordVsCta())
    ?.replaceAll("{{medical_CTA}}", medicalCta())
    ?.replaceAll("{{medical_CTA_light}}", medicalCTALight())
    ?.replaceAll("{{product_sam_cta}}", productSamCta())
    ?.replaceAll("{{GPT_Vision_CTA}}", gptVisionCTA())
    ?.replaceAll("{{Apollo_CTA}}", apolloCTA())
    ?.replaceAll("{{Active_CTA}}", activeCTA())
    ?.replaceAll("{{CTA_Product_Active}}", productActiveCTA())
    ?.replaceAll("{{RLHF_CTA}}", rlhfCTA())
    ?.replaceAll("{{Webinar_VLM_CTA}}", webinarVLMCTA())
    ?.replaceAll(
      "{{gray_callout_start}}",
      `<div class="bg-gray-1900 px-4 py-5 rounded-lg">`
    )
    ?.replaceAll("{{gray_callout_end}}", "</div>")
    ?.replaceAll(
      "{{light_callout_start}}",
      `<div class="flex items-center gap-5 bg-gray-2000 px-4 py-5 rounded-lg">
        <img src="https://encord.cdn.prismic.io/encord/a4f4a72a-cc07-4292-a306-1b0651d880a8_light-cta-img.svg" width="26px" height="28px" alt="light-callout-cta"/>
        <span>
      `
    )
    ?.replaceAll("{{light_callout_end}}", "</span></div>");

  __html = addAltToImgTags(__html);
  return {
    __html,
  };
};

const GlossaryDetail = ({ data, location }) => {
  const _data = data.prismicGlossaryDetailPage?.data;
  const [faq_schema] = pickSlice(_data, "faq_schema");

  const { __html } = stringManipulation(_data?.page_content?.html);
  return (
    <Layout loadLayoutWithScriptsOnly>
      {faq_schema?.items?.length && (
        <Helmet>
          <script type="application/ld+json">
            {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [
                ${faq_schema?.items
                  ?.map((item) => {
                    const { question, answer } = item;
                    return `{
                      "@type": "Question",
                      "name": "${question?.text}",
                      "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "${answer?.text}"
                      }
                    }`;
                  })
                  .join(",")}
              ]
            }
          `}
          </script>
        </Helmet>
      )}

      <div className="flex flex-col min-h-screen overflow-clip">
        <Header location={location} />
        <LeftColours />
        <main className="flex-grow ">
          <RightColours />
          <section className="px-6 pt-20 pb-12 mx-auto lg:pt-32 md:pb-20 max-w-screen-2xl">
            <div className="grid grid-cols-1 md:grid-cols-1 lg:md:grid-cols-12">
              <section className="col-span-12 md:col-span-3 order-2 md:order-2 lg:col-span-2 max-h-[calc(100vh - 200px)] pb-8 lg:pb-0 block">
                <div className="sticky top-32">
                  <CustomLink to="/glossary/" className="flex mt-4 text-md">
                    <svg
                      className="mr-2 img-fluid"
                      xmlns="https://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M10.5 19.5L11.5575 18.4425L5.8725 12.75H21V11.25H5.8725L11.5575 5.5575L10.5 4.5L3 12L10.5 19.5Z"
                        fill="#171717"
                      />
                    </svg>
                    Back to glossary
                  </CustomLink>
                </div>
              </section>

              <section className="order-3 col-span-8 encode-blogs-content md:order-2 md:col-span-7 lg:col-span-7">
                <div className="px-0 mb-12 lg:px-2">
                  <h1 className="relative mb-4 h1">
                    {_data?.page_heading.text}
                  </h1>
                  <p className="">
                    {_data.page_description.text ||
                      "Encord Computer Vision Glossary"}
                  </p>
                  <div dangerouslySetInnerHTML={{ __html: __html }} />
                </div>
              </section>

              <section className="order-3 col-span-12 md:order-3 md:col-span-3">
                <div className="sticky col-span-2 top-32 ">
                  <div className="flex flex-col items-center lg:items-start mt-6 gap-4 max-w-[230px] lg:max-w-[250px] mx-auto ">
                    <h6 className="hidden text-lg font-semibold md:block">
                      Follow us
                    </h6>
                    <SocialMediaHandles iconColor="#1D0642" />
                  </div>

                  <>
                    <CtaSide
                      imageSrc="https://encord.cdn.prismic.io/encord/99053e72-1b89-4478-a821-8cedc78e2086_slack.svg"
                      title="Discuss this blog on Slack"
                      description="Join the Encord Developers community to discuss the latest in computer vision, machine learning, and data-centric AI"
                      btnText="Join the community"
                      btnLink="https://encordactive.slack.com/join/shared_invite/zt-24z7s3k0k-k8IcEU4iMz93erouJtVCPA#/shared-invite/email"
                    />

                    <CtaSide
                      imageSrc="https://encord.cdn.prismic.io/encord/02d98cad-a7bc-48f2-aba4-2d723e0325e5_side-cta-icon.svg"
                      title={`Automate <span style="color:#FFEE51">97%</span> of your annotation tasks with <span style="color:#FFEE51">99%</span> accuracy`}
                      btnText="Book a demo"
                      btnColor="text-purple-1700"
                      btnBgColor="btn_bg_cta_blog"
                      cardBgColor="custom_cta_card_bg"
                      titleText="text-white mt-0.5 mb-4"
                      isDemo
                      location={location}
                      flexGap="gap-y-0"
                      isFullWidth
                    />
                  </>
                </div>
              </section>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query PrismicGlossaryDetailPage($uid: String!) {
    prismicGlossaryDetailPage(uid: { eq: $uid }) {
      data {
        meta_description {
          text
        }
        meta_title {
          text
        }
        page_content {
          html
          text
        }
        page_description {
          text
        }
        page_heading {
          text
        }
        body {
          ... on PrismicGlossaryDetailPageDataBodyFaqSchema {
            slice_type
            items {
              answer {
                text
              }
              question {
                text
              }
            }
          }
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const _data = data?.prismicGlossaryDetailPage?.data;
  return (
    <SEO
      title={
        _data?.meta_title?.text?.split("|").length === 2
          ? `${_data?.meta_title?.text}`
          : `${_data?.meta_title?.text} | Encord`
      }
      description={
        _data?.meta_description?.text || _data?.page_content?.text.slice(0, 144)
      }
    />
  );
};

export default GlossaryDetail;
